import AutoscribeLogo from '@assets/images/autoscribe.svg?react'
import React from 'react'

interface LogoLoaderProps {
  className?: string
}

const LogoLoader: React.FC<LogoLoaderProps> = ({ className }) => {
  return (
    <div className={`flex h-screen items-center justify-center ${className || ''}`}>
      <AutoscribeLogo className="flex size-80 animate-pulse items-center justify-center" />
    </div>
  )
}

export default LogoLoader
