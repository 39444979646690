import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { useEffect } from 'react'
import { loadMessages } from '../i18n'

interface MessagesProviderProps {
  children: React.ReactNode
  defaultLocale: string
}

export const MessagesProvider = ({ children, defaultLocale }: MessagesProviderProps) => {
  useEffect(() => {
    loadMessages(defaultLocale)
  }, [children, defaultLocale])

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}
