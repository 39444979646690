export type LocalStorageValue = string | number | boolean | object | null

const parseStoredValue = (value: string | null): LocalStorageValue | null => {
  if (value === null) return null
  try {
    return JSON.parse(value)
  } catch (e) {
    return value
  }
}

export const recoilPersistKey = 'recoil-persist'

export const localStorage = {
  getItem: (key: string, useRecoilPersist = false): LocalStorageValue | null => {
    const recoilStore = JSON.parse(window.localStorage.getItem(recoilPersistKey) as string)
    return parseStoredValue(useRecoilPersist && recoilStore ? recoilStore[key] : window.localStorage.getItem(key))
  },

  setItem: (key: string, value: LocalStorageValue, useRecoilPersist = false): void => {
    const recoilStore = JSON.parse(window.localStorage.getItem(recoilPersistKey) as string)
    if (useRecoilPersist) {
      window.localStorage.setItem(recoilPersistKey, JSON.stringify({ ...recoilStore, [key]: value }))
    } else {
      window.localStorage.setItem(key, JSON.stringify(value))
    }
  },

  removeItem: (key: string, useRecoilPersist = false): void => {
    const recoilStore = JSON.parse(window.localStorage.getItem(recoilPersistKey) as string)
    if (useRecoilPersist) {
      delete recoilStore[key]
      window.localStorage.setItem(recoilPersistKey, JSON.stringify(recoilStore))
    } else {
      window.localStorage.removeItem(key)
    }
  },

  clear: (useRecoilPersist = false) => {
    if (useRecoilPersist) {
      window.localStorage.clear()
    } else {
      const recoilStore = JSON.parse(window.localStorage.getItem(recoilPersistKey) as string)
      window.localStorage.clear()
      window.localStorage.setItem(recoilPersistKey, JSON.stringify(recoilStore))
    }
  },
}
