import { i18n } from '@lingui/core'

export const locales = {
  en: 'English',
  fr: 'Français',
  de: 'Deutsch',
  es: 'Español',
  it: 'Italiano',
  pl: 'Polski',
  pt: 'Português',
}
export const defaultLocale = 'fr'

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function loadMessages(locale: string) {
  const importLocale = locales[locale] === undefined ? defaultLocale : locale
  const { messages } = await import(`./locales/${importLocale}.po`)
  i18n.load(importLocale, messages)
  i18n.activate(importLocale)
}
