import Subscription from '@enums/subscription.enum'
import { User } from '@interfaces/user.interface'
import { Trans } from '@lingui/macro'

export function formatSecondsToTime(seconds: number): string {
  const roundedSeconds = Math.round(seconds)
  const h = Math.floor(roundedSeconds / 3600)
  const m = Math.floor((roundedSeconds % 3600) / 60)
  const s = roundedSeconds % 60

  const hours = h > 0 ? `${h}h ` : ''
  const minutes = m > 0 ? `${m}m ` : ''
  const secs = s > 0 ? `${s}s ` : ''

  return `${hours}${minutes}${secs}`.trim()
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function getTrialExpirationWording(daysLeft: number) {
  if (daysLeft === 0) {
    return <Trans>today</Trans>
  } else if (daysLeft === 1) {
    return <Trans>tomorrow</Trans>
  } else {
    return <Trans>in {daysLeft} days</Trans>
  }
}

export function getBillingBasisWording(user: User) {
  if ([Subscription.PREMIUM_ANNUALLY, Subscription.ENTERPRISE].includes(user.subscription)) {
    return <Trans>yearly</Trans>
  } else {
    return <Trans>monthly</Trans>
  }
}

export function getTrialExpirationSubscriptionInDays(user: User) {
  return user?.subscription === Subscription.PREMIUM_TRIAL
    ? Math.floor((new Date(user?.trialEndsAt).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))
    : 0
}

export function isTrialActive(user: User): boolean {
  const now = new Date()
  return user.subscription === Subscription.PREMIUM_TRIAL && new Date(user.trialEndsAt) > now && user.trialConsumption > 0
}

export function isSubscriptionActive(user: User): boolean {
  const now = new Date()
  return user.stripeSubscriptionStatus === 'active' && new Date(user.subscriptionExpires) > now
}

export function isSubscriptionCancelled(user: User): boolean {
  const now = new Date()
  return (
    [Subscription.PREMIUM_MONTHLY, Subscription.PREMIUM_ANNUALLY, Subscription.ENTERPRISE].includes(user.subscription) &&
    (user.stripeSubscriptionStatus !== 'active' || (new Date(user.subscriptionExpires) && new Date(user.subscriptionExpires) <= now))
  )
}

export function hasNoSubscription(user: User): boolean {
  return !isTrialActive(user) && !isSubscriptionActive(user) && !isSubscriptionCancelled(user) && !isSubscriptionPartner(user)
}

export function isSubscriptionPartner(user: User): boolean {
  return user.subscription === Subscription.PARTNER
}

export function getSubscriptionType(user: User): 'Premium' | 'Enterprise' | 'Partner' | null {
  if ([Subscription.PREMIUM_TRIAL, Subscription.PREMIUM_MONTHLY, Subscription.PREMIUM_ANNUALLY].includes(user.subscription)) {
    return 'Premium'
  } else if (user.subscription === Subscription.ENTERPRISE) {
    return 'Enterprise'
  } else if (user.subscription === Subscription.PARTNER) {
    return 'Partner'
  }
  return null
}
