import { ASRModel } from '@enums/asr-model.enum'
import { Language } from '@enums/language.enum'
import { LLMModel } from '@enums/llm-model.enum'
import { User } from '@interfaces/user.interface'
import { RecoilState, atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const USER_STATE_KEY = 'userState'
export const userState: RecoilState<User> = atom({
  key: USER_STATE_KEY,
  default: {
    id: '',
    email: '',
    socials: [],
    password: '',
    phone: '',
    firstName: '',
    lastName: '',
    picture: '',
    verified: false,
    loginAttempts: 0,
    blockExpires: new Date(),
    role: 'user',
    subscription: 'free',
    subscriptionExpires: new Date(),
    settings: {
      language: Language.EN,
      asrSystemPrompt: '',
      asrModel: ASRModel.ASSEMBLYAI_UNIVERSAL1,
      llmSystemPrompt: '',
      llmModel: LLMModel.AZURE_OPENAI_GPT4,
      vocabulary: [],
      creativityLevel: 0.5,
    },
  },
  effects_UNSTABLE: [persistAtom],
})
