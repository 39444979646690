import { USER_STATE_KEY } from '@atoms/user.atom.js'
import LogoLoader from '@components/loader/logo.loader.js'
import { Language } from '@enums/language.enum.js'
import { User } from '@interfaces/user.interface.js'
import * as Sentry from '@sentry/react'
import { localStorage } from '@services/local-storage.service.js'
import '@stripe/stripe-js'
import { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App.js'
import { MessagesProvider } from './providers/messages.provider.js'
import { StoreProvider } from './providers/store.provider.js'
import './styles/index.style.css'

Sentry.init({
  dsn: 'https://de52307dfe378e7986826eab758113cc@o4507016029405184.ingest.us.sentry.io/4507016032419840',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  enabled: import.meta.env.MODE !== 'development',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/autoscribe\.steamulo.com\/api/,
    /^https:\/\/autoscribe-demo\.steamulo.org\/api/,
    /^https:\/\/autoscribe-sante\.steamulo.com\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.MODE ?? '',
})

const settings = (localStorage.getItem(USER_STATE_KEY, true) as User)?.settings
export const defaultLocale = settings?.language || navigator?.language?.split('-')[0] || Language.EN

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <StoreProvider>
      <MessagesProvider defaultLocale={defaultLocale}>
        <Router>
          <Suspense fallback={<LogoLoader />}>
            <App />
          </Suspense>
        </Router>
      </MessagesProvider>
    </StoreProvider>
  </StrictMode>,
)
