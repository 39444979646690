import { createContext, useContext } from 'react'

interface SubscriptionBannerContextType {
  bannerDisplay: boolean
  setBannerDisplay: (state: boolean) => void
}

export const SubscriptionBannerContext = createContext<SubscriptionBannerContextType | null>(null)

export const useSubscriptionBanner = () => {
  const context = useContext(SubscriptionBannerContext)
  return context
}
