import AuthTokens from '@interfaces/auth.interface'
import { RecoilState, atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const AUTH_TOKENS_STATE_KEY = 'authTokensState'
export const authTokensState: RecoilState<AuthTokens | null> = atom({
  key: AUTH_TOKENS_STATE_KEY,
  default: {
    accessToken: null,
    refreshToken: null,
    expiresIn: null,
  },
  effects_UNSTABLE: [persistAtom],
})
